<template>
  <div class="container pt-3">
    <div class="box-card mt-3">
      <div class="titleStyle">
        <h1 class="primaryColor" style="font-size: 30px;">
          {{ title }}
        </h1>
        <el-divider></el-divider>
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane :label="$t('userProfile.basicInfo')" name="info">
            <Heading :content="$t('userProfile.basic.title')"></Heading>
            <hr />
            <el-form
              v-if="userInfo"
              ref="form"
              :model="userInfo"
              label-width="120px"
            >
              <el-form-item :label="'Name'">
                <b class="text-success">{{ title }}</b>
              </el-form-item>
              <template>
                <el-form-item :label="$t('userProfile.basic.email')">
                  <b class="text-success">
                    {{ userInfo.email }}
                  </b>
                </el-form-item>
              </template>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="My Plans" name="plans">
            <Heading content="My Plans"></Heading>
            <hr />
            <MyPlans v-if="myPlans" :myPlans="myPlans" @getPlans="getUserPlans"></MyPlans>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import { instant, user } from "@ivy-way/material";
import userApi from "@/apis/user";
import SAT from "@/apis/sat";
import baseDomainPath from "@/common/baseDomainPath";
import Theme from "@/common/theme";
import moment from "moment";
import MyPlans from "@/views/profile/components/MyPlans.vue";

export default {
  metaInfo() {
    return {
      title: `${this.title} - ${this.CompanyName}`
    };
  },

  components: { MyPlans },

  mixins: [],

  props: [],
  data() {
    return {
      moment,
      myPlans: null,
      activeName: "info",
      userInfo: null,
      userProfile: null
    };
  },
  computed: {
    ...mapGetters("user", ["token", "getProfile"]),
    theme() {
      return Theme;
    },
    userId() {
      return this.getProfile.id;
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    teacherViewUser() {
      return (
        this.$route.name == "TeacherViewUser" ||
        this.$route.name == "CompanyAdminViewUser"
      );
    },
    myProfile() {
      return this.$route.name === "UserProfile";
    },
    adminViewUser() {
      return this.$route.name == "AdminViewUser";
    },
    title() {
      if (this.userInfo) {
        return user.displayName(
          this.userInfo.first_name,
          this.userInfo.last_name
        );
      } else {
        return " - ";
      }
    }
  },
  watch: {
    userId(userId) {
      if (userId) {
        this.getUserProfile();
      }
    }
  },
  mounted() {
    if (this.$route.name === "UserProfile") {
      this.activeName = "info";
    }
    if (this.$route.query.active) {
      this.activeName = this.$route.query.active;
    }
    if (this.userId) {
      this.getUserProfile();
    }
  },
  methods: {
    async getUserProfile() {
      const res = this.getProfile;
      this.userInfo = res;
      this.userProfile = res;
      this.getUserPlans();
    },
    async getUserPlans() {
      const res = await userApi.getUserPlans(this.userId, {
        status: "active"
      });
      this.myPlans = res;
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  border: 1px solid #ebeef5;
  padding: 0 20px;
  margin-right: 10px;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
}
.box-card {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.proIcon {
  display: inline-block;
  margin: 0px 2px;
  color: orange;
}
</style>
